import React from "react";
import SbEditable from "storyblok-react";
import config from "../../gatsby-config";
import Page from "../components/Page";
import Show from "../components/Show";
import Layout from "../components/Layout";

const sbConfigs = config.plugins.filter(
	(item) => item.resolve === "gatsby-source-storyblok",
);
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = (cb) => {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
	script.onload = cb;
	document.getElementsByTagName("head")[0].appendChild(script);
};

const forceToBoolean = (str) => Boolean(Number(str));

class StoryblokEntry extends React.Component {
	constructor(props) {
		super(props);
		this.state = { story: null };
	}

	componentDidMount() {
		loadStoryblokBridge(() => {
			this.initStoryblokEvents();
		});
	}

	loadStory() {
		window.storyblok.get(
			{
				slug: window.storyblok.getParam("path"),
				version: "draft",
				resolve_relations: sbConfig.options.resolveRelations || [],
			},
			(data) => {
				this.setState({ story: data.story });
			},
		);
	}

	initStoryblokEvents() {
		this.loadStory();

		const sb = window.storyblok;

		sb.on(["change", "published"], (payload) => this.loadStory(payload));

		sb.on("input", (payload) => {
			const { story } = this.state;
			if (story && payload.story.id === story.id) {
				const contentWithComments = sb.addComments(
					payload.story.content,
					payload.story.id,
				);
				const payloadStory = { ...payload.story, content: contentWithComments };
				this.setState({ story: payloadStory });
			}
		});

		sb.pingEditor(() => {
			if (sb.inEditor) {
				sb.enterEditmode();
			}
		});
	}

	render() {
		const isEditorEnabled = forceToBoolean(
			process.env.GATSBY_IS_EDITOR_MODE_ENABLED,
		);
		if (!isEditorEnabled) {
			return null;
		}
		const { story } = this.state;
		const { content = {} } = story || {};
		const { uid } = content;
		if (story == null) {
			return <div />;
		}

		const { component } = content;

		if (component === "show") {
			return (
				<Layout>
					<SbEditable content={content}>
						<Show blok={content} />
					</SbEditable>
				</Layout>
			);
		}

		return (
			<SbEditable content={content}>
				<div>
					<Page key={uid} blok={content} />
				</div>
			</SbEditable>
		);
	}
}

export default StoryblokEntry;
