require("dotenv").config();

const forceToBoolean = (str) => Boolean(Number(str));

const isEditorEnabled = forceToBoolean(
	process.env.GATSBY_IS_EDITOR_MODE_ENABLED,
);

module.exports = {
	siteMetadata: {
		title: `Ben R Clement`,
		description: `Ben R Clement is a visual artist born in NZ, based in NY`,
		author: "Ben Clement",
		siteUrl: "http://ben-r-clement.com/",
	},
	plugins: [
		"gatsby-plugin-react-helmet",
		"gatsby-plugin-theme-ui",
		{
			resolve: "gatsby-source-storyblok",
			options: {
				accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
				homeSlug: "home",
				version: isEditorEnabled ? "draft" : "published",
			},
		},
		{
			resolve: "gatsby-plugin-web-font-loader",
			options: {
				custom: {
					families: ["Attribut Beta"],
					urls: ["/webfonts.css"],
				},
			},
		},
		{
			resolve: "gatsby-plugin-robots-txt",
			options: {
				host: "https://ben-r-clement.com/",
				sitemap: "https://ben-r-clement.com/sitemap.xml",
				policy: [
					isEditorEnabled
						? { userAgent: "*", disallow: ["/"] }
						: { userAgent: "*", allow: "/" },
				],
			},
		},
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				name: `images`,
				path: `${__dirname}/src/images`,
			},
		},
		`gatsby-transformer-sharp`,
		`gatsby-plugin-sharp`,
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: `gatsby-starter-default`,
				short_name: `starter`,
				start_url: `/`,
				background_color: `#000000`,
				theme_color: `#000000`,
				display: `minimal-ui`,
			},
		},
		{
			resolve: `gatsby-plugin-google-analytics`,
			options: {
				// The property ID; the tracking code won't be generated without it
				trackingId: process.env.GATSBY_GOOGLE_ANALYTICS_TRACK_ID,
				// Defines where to place the tracking script - `true` in the head and `false` in the body
				head: true,
				// Setting this parameter is optional
				anonymize: true,
				// Setting this parameter is also optional
				respectDNT: true,
				// Avoids sending pageview hits from custom paths
				exclude: ["/editor/**"],
				// Delays sending pageview hits on route update (in milliseconds)
				pageTransitionDelay: 0,
				cookieDomain: "ben-r-clement.com",
			},
		},
	],
};
